<template lang="pug">
.lh-small-article-card(
  @mouseover="onMouseOver"
  ref="articleCard"
)
  TheLinkUi.lh-small-article-card__link(
    data-jest="link"
    @click.native="onClickLink"
    @contextmenu.native="onContextMenu"
    v-bind="{href: relativeLink, name: title , target: isTeaser ? '_blank' : ''}"
  )

  .lh-small-article-card__cover
    AppPicture(
      :images="images"
      :defaultImages="defaultImages"
      :alt="imgAlt || title"
      :sizesSliced="[72, 208, 390]"
      :sizesByDevice="{ mobile: '72px', tablet: '390px' }"
      :loading="imgLoading"
    )

  .lh-small-article-card__meta
    AdminControlsPanel.lh-small-article-card__meta-admin-controls(
      v-if="isAuthenticatedInWordpress"
      :viewsCount="viewsCount"
      :editLinkHref="editLinkHref"
      @click-on-restore-button="onClickRestoreButton"
      :loading="loadingViews"
    )
    .lh-small-article-card__title(
      data-jest="title"
      v-html="title"
    )
    .lh-small-article-card__controls-and-meta
      ArticleCardControls.lh-small-article-card__controls(
        v-bind="getArticleCardControlsProps($props)"
        @clicked-on-comments="onClickedComments"
        @favorite-delete="onFavoriteDeleteEmit"
        @favorite-create-folder-and-add="onFavoriteCreateFolderAndAddEmit"
        @favorite-create-folder-and-transfer="onFavoriteCreateFolderAndTransferEmit"
        @favorite-transfer-to-folder="onFavoriteTransferToFolderEmit"
        @favorite-add-to-folder="onFavoriteAddToFolderEmit"
        @favorite-deferred="onFavoriteDeferredEmit"
        @favorite-action="onFavoriteAction"
      )
      ArticleCardMeta.lh-small-article-card__meta-data(
        :dateText="dateText"
        :isPromo="isPromo"
        :isAdvertising="isAdvertising"
        :categories="categories"
        :partnerText="partnerText"
        :disableBreadcrumbCircularLink="disableBreadcrumbCircularLink"
        :tags="tags"
        :type="type"
        @clicked-on-author="onClickedOnAuthor"
        @clicked-on-category="onClickedOnCategory"
      )
</template>

<script lang="ts">
  import type { PropType } from 'vue';

  import type { Img } from '@devhacker/shared/utils/image/types';
  import TheLinkUi from '@/components/lh-ui/TheLinkUi/TheLink.vue';
  import ArticleCardControls from '@/components/lh-ui/ArticleCardControls/ArticleCardControls.vue';
  import AdminControlsPanel from '@/components/lh-ui/AdminControlsPanel/AdminControlsPanel.vue';
  import ArticleCardMeta from '@/components/lh-ui/ArticleCardMeta/ArticleCardMeta.vue';

  import AppPicture from '@/components/lh-ui/AppPicture/AppPicture.vue';
  import { getArticleCardControlsProps, onFavoriteAction } from '@/utils/articleCard';
  import { removeLifehackerFromUrl } from '~/utils/removeLifehackerFromUrl';

  export default defineNuxtComponent({
    name: 'ArticleCardSmall',
    components: {
      ArticleCardControls,
      TheLinkUi,
      AdminControlsPanel,
      ArticleCardMeta,
      AppPicture,
    },
    props: {
      disableBreadcrumbCircularLink: {
        type: Boolean,
        default: false,
      },

      url: {
        type: String as PropType<string>,
        required: true,
      },
      internalUrl: {
        type: Boolean as PropType<boolean>,
        required: false,
        default: false,
      },
      title: {
        type: String as PropType<string>,
        required: true,
      },
      imgAlt: {
        type: String as PropType<string>,
        required: false,
        default: '',
      },
      imgLoading: {
        type: String as PropType<'eager' | 'auto' | 'lazy'>,
        default: 'lazy',
      },
      type: {
        type: String as PropType<string>,
        default: '',
      },
      images: {
        type: Array as PropType<Img[]>,
        required: false,
        default: () => [],
      },
      defaultImages: {
        type: Array as PropType<Img[]>,
        required: false,
        default: () => [],
      },
      dateText: {
        type: String as PropType<string>,
        required: true,
      },
      categories: {
        type: Array as PropType<Array<IArticleCategory>>,
        required: false,
        default: () => [],
      },
      tags: {
        type: Array as PropType<Array<IArticleTags>>,
        required: false,
        default: () => [],
      },
      commentsEnabled: {
        type: Boolean as PropType<boolean>,
        required: true,
      },
      commentsCount: {
        type: Number as PropType<number>,
        required: false,
        default: 0,
      },
      authorText: {
        type: String as PropType<string>,
        required: false,
        default: null,
      },
      authorUrl: {
        type: String as PropType<string>,
        required: false,
        default: null,
      },
      isAdvertising: {
        type: Boolean as PropType<boolean>,
        required: false,
        default: false,
      },
      isPromo: {
        type: Boolean as PropType<boolean>,
        required: false,
        default: false,
      },
      isTeaser: {
        type: Boolean as PropType<boolean>,
        required: false,
        default: false,
      },
      isAuthenticatedInWordpress: {
        type: Boolean as PropType<boolean>,
        required: false,
        default: false,
      },
      editLinkHref: {
        type: String as PropType<string>,
        required: false,
        default: null,
      },
      viewsCount: {
        type: Number as PropType<number>,
        required: false,
        default: null,
      },
      user: {
        type: Object as PropType<IUser>,
        default: null,
      },
      favorite: {
        required: false,
        type: Object as PropType<IFavoriteItem | null>,
        default: null,
      },
      folders: {
        required: true,
        type: Object as PropType<IFolders>,
      },
      isShowFavoriteFolderName: {
        type: Boolean,
        default: false,
      },
      favoriteMenuOffsets: {
        type: Object as PropType<TOffsets | null>,
        default: null,
      },
      loadingViews: {
        type: Boolean as PropType<boolean>,
        required: true,
      },
      coverSize: {
        type: String as PropType<string>,
        default: '',
      },
      adsToken: {
        default: '',
        type: String as PropType<string>,
      },
      advertLabel: {
        default: '',
        type: String as PropType<'empty' | 'partner' | 'abs' | 'press-release' | 'social-ads'>,
      },
      partnerText: {
        type: String as PropType<string>,
        default: '',
      },
    },

    emits: [
      'click-on-article-card',
      'clicked-on-comments',
      'clicked-on-category',
      'clicked-on-author',
      'favorite-deferred',
      'favorite-add-to-folder',
      'favorite-transfer-to-folder',
      'favorite-create-folder-and-transfer',
      'favorite-create-folder-and-add',
      'favorite-delete',
      'click-on-restore-button',
      'show-context-menu',
    ],

    computed: {
      relativeLink() {
        return removeLifehackerFromUrl(this.url);
      },
    },
    methods: {
      getArticleCardControlsProps,
      onFavoriteAction,
      onMouseOver(): void {
        const articleCard = this.$refs.articleCard as HTMLElement;
        if (articleCard) {
          articleCard.classList.remove('lh-article-card--no-hover');
        }
      },
      onClickedComments(link: string) {
        this.$emit('clicked-on-comments', link);
      },
      onClickedOnCategory(link: string, categoryName: string) {
        this.$emit('clicked-on-category', link, categoryName, this.title || '');
      },
      onClickedOnAuthor(link: string): void {
        this.$emit('clicked-on-author', link);
      },
      onClickLink() {
        this.$emit('click-on-article-card');
      },
      onContextMenu() {
        this.$emit('show-context-menu');
      },
      onFavoriteDeferredEmit(deferredAction: Function, ctx: any) {
        this.$emit('favorite-deferred', deferredAction, ctx);
      },
      onFavoriteAddToFolderEmit(folder: IFolderItem) {
        this.$emit('favorite-add-to-folder', folder);
      },
      onFavoriteTransferToFolderEmit(favorite: IFavoriteItem, folder: IFolderItem) {
        this.$emit('favorite-transfer-to-folder', favorite, folder);
      },
      onFavoriteCreateFolderAndTransferEmit(nameFolder: string, favorite: IFavoriteItem): void {
        this.$emit('favorite-create-folder-and-transfer', nameFolder, favorite);
      },
      onFavoriteCreateFolderAndAddEmit(nameFolder: string): void {
        this.$emit('favorite-create-folder-and-add', nameFolder);
      },
      onFavoriteDeleteEmit(favorite: IFavoriteItem): void {
        this.$emit('favorite-delete', favorite);
      },
      onClickRestoreButton() {
        this.$emit('click-on-restore-button');
      },
    },
  });
</script>

<style lang="scss" src="./ArticleCardSmall.scss"></style>
